<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('learn.onboarding.overview')" class="catalog onboarding_packages">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
              {{$t('learn.Onboarding')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showInactive" size="is-small" @input="getOnboardingPackages()">{{ $t('common.show_inactive') }}</b-switch>
              <div class="ml-2 mt-1 mt-xl-0 d-inline-block align-top">
                <b-switch class="mt-0 mb-0" v-model="contentFocus" size="is-small">{{ $t('learn.Focus_on_package_content') }}</b-switch>
                <CButton v-if="checkPermission('learn.insights.onboarding')" class="ml-2 m-0" color="primary" @click="showOnboardingInsights()">
                  <i class="far fa-chart-bar mr-2"/>
                  <span>{{ $t('learn.Onboarding_insights') }}</span>
                </CButton>
              </div>
            </CCol>
          </CRow>          
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow v-if="!contentFocus" class="m-0">
            <CCol cols="12" lg="12" class="pt-0">              
              <multiselect
                class="open_absolute"
                v-model="filter.target_groups" 
                :options="targetGroups" 
                :multiple="true"
                :placeholder="$t('common.Select_group')" 
                :selectLabel="$t('common.Add_group')" 
                :selectedLabel="$t('Added')"
                :deselectLabel="$t('common.Remove_group')"
                track-by="group_id" 
                label="title"
                @input="getOnboardingPackages();">
                <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
              </multiselect> 
            </CCol>
          </CRow>
          <CRow class="m-0">
            <CCol v-if="!contentFocus" cols="6" lg="6" class="p-0">
              <div>
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">
                    <span class="text-uppercase">
                      <b>{{$t('learn.Onboarding_packages')}}</b>
                    </span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('learn.onboarding.addpackage')" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="mr-0 link" color="primary" @click="openSidebarRight('onboarding_package_details', { onboarding_package_id_external:  null });">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{ $t('learn.Add_onboarding_package') }}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !onboardingPackagesLoading}">
                    <div v-if="onboardingPackagesLoading">
                      <loadingSpinner mode="auto" v-if="onboardingPackagesLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table class="data_table add_bottom_left_radius"
                               ref="onboardingPackageTable"
                               :data="onboardingPackages"
                               :striped="true"
                               :hoverable="true"
                               :mobile-cards="true"
                               :paginated="onboardingPackagesPaginated"
                               :per-page="onboardingPackagesPerPage"
                               :current-page.sync="onboardingPackagesPage"
                               :pagination-simple="isPaginationSimple"
                               :pagination-position="paginationPosition"
                               :total="onboardingPackageTotal"
                               backend-pagination
                               @page-change="onOnboardingPackagePageChange"
                               :row-class="(row, index) => row.onboarding_package_id_external === activeOnboardingPackage.onboarding_package_id_external && 'active'">
                
                        <template slot-scope="props">
                          <b-table-column field="package_name" :label="$t('common.Name')" :searchable="searchEnabled">
                            <span class="pointer" @click="getOnboardingPackageContent(props.row.package_name, props.row.onboarding_package_id_external);">                        
                              <b>{{props.row.package_name}}</b>
                            </span>
                            <div class="item_tags d-flex flex-row">
                              <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                            </div>                                                    
                          </b-table-column>
                          <b-table-column field="target_groups" label="" class="text-right" width="10%">
                            <targetGroupPopover v-if="props.row.target_groups.length > 1 || (props.row.target_groups[0] && props.row.target_groups[0].default_company_group === 'N')"
                                                targetGroupsType="learn onboarding package"
                                                :targetGroupsIdExternal="props.row.onboarding_package_id_external"
                                                popoverPlacement="left"/>
                          </b-table-column>
                          <b-table-column field="action" width="10%" :visible="checkPermission('learn.onboarding.editpackage')">
                            <div class="d-flex justify-content-lg-end">
                              <CButton class="m-0 link" color="primary" @click="openSidebarRight('onboarding_package_details', { onboarding_package_id_external: props.row.onboarding_package_id_external })">
                                <i class="fas fa-pen"/>
                              </CButton>
                            </div>
                          </b-table-column>
                        </template>
                        <template slot="empty">
                          <div class="p-2 text-center">
                            <span>{{ $t('learn.No_onboarding_packages_found') }}</span>
                          </div>                
                        </template>                              
                      </b-table>
                    </div>            
                  </CCol>
                </CRow>
              </div>
            </CCol>

            <CCol :cols="!contentFocus ? 6 : 12" :lg="!contentFocus ? 6 : 12" class="p-0">
              <div class="package_content">
                <CRow class="m-0 actions">
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-left">                    
                    <span v-if="activeOnboardingPackage.onboarding_package_id_external" v-line-clamp="1" class="text-uppercase">
                      <b>{{activeOnboardingPackage.package_name}}</b>
                    </span>
                    <span v-else class="text-uppercase"><b>{{$t('learn.Onboarding_package_content')}}</b></span>
                  </CCol>
                  <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
                    <div v-if="checkPermission('learn.onboarding.packagecontent') && activeOnboardingPackage.onboarding_package_id_external" class="mt-1 mt-xl-0 d-inline-block align-top">
                      <CButton class="m-0 link"
                               color="primary"
                               @click="openSidebarRight('onboarding_package_content_details', { onboarding_package: activeOnboardingPackage, onboarding_package_content_id_external: null })">
                        <i class="fas fa-plus mr-1"/>
                        <span>{{$t('learn.Add_onboarding_package_content')}}</span>
                      </CButton>
                    </div>
                  </CCol> 
                </CRow>
                <CRow v-if="activeOnboardingPackage.onboarding_package_id_external">
                  <CCol cols="12" lg="12" v-bind:class="{'pb-0' : !onboardingPackageContentLoading}">
                    <div v-if="onboardingPackageContentLoading">
                      <loadingSpinner mode="auto" v-if="onboardingPackageContentLoading" :content="$t('common.Loading')"/>                            
                    </div>
                    <div v-else>                    
                      <b-table ref="onboardingPackageContentTable"
                              class="data_table includes_dropdown add_bottom_right_radius"
                              :data="onboardingPackageContent"
                              :striped="true"
                              :hoverable="true"
                              :mobile-cards="true"
                              :paginated="onboardingPackageContentPaginated"
                              :per-page="onboardingPackageContentPerPage"
                              :current-page.sync="onboardingPackageContentPage"
                              :pagination-simple="isPaginationSimple"
                              :pagination-position="paginationPosition">
                            
                            <template slot-scope="props">                              
                              <b-table-column field="content_name" :label="$t('learn.Onboarding_package_content_name')" :searchable="searchEnabled">
                                <div class="d-flex align-items-center">
                                  <div v-if="props.row.content_type" class="d-flex align-items-center justify-content-center content_type_icon">
                                    <i v-if="props.row.content_type.onboarding_package_content_type_tag === 'learn_course'" class="fas fa-graduation-cap"/>
                                    <i v-if="props.row.content_type.onboarding_package_content_type_tag === 'knowledge_item'" class="fas fa-book"/>
                                  </div>
                                  <div v-if="props.row.content_name" class="flex-grow-1">
                                    <span>{{props.row.content_name}}</span>
                                  </div>
                                  <div v-else-if="props.row.content_type" class="flex-grow-1">
                                    <div v-if="props.row.content_type.onboarding_package_content_type_tag === 'learn_course' && props.row.course">
                                      <span>{{props.row.content_type.onboarding_package_content_type + ' - ' + props.row.course.course_name}}</span>                                                
                                    </div>
                                    <div v-else-if="props.row.content_type.onboarding_package_content_type_tag === 'knowledge_item' && props.row.item">
                                      <span>{{props.row.content_type.onboarding_package_content_type + ' - ' + props.row.item.kb_intent_title}}</span>
                                    </div>
                                    <div v-else>
                                      <span>{{props.row.content_type.onboarding_package_content_type}}</span>                                                
                                    </div>
                                  </div>
                                </div>
                              </b-table-column>
                              <b-table-column field="action" width="10%">
                                <div class="d-flex justify-content-lg-end">
                                  <CDropdown v-if="checkPermission('learn.onboarding.packagecontent')"
                                             color="primary"
                                             toggler-text=""
                                             :caret="false"
                                             class="ml-2 table_actions_dropdown">
                                    <div slot="toggler-content">
                                      <span class="d-flex align-items-center">
                                        {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                                      </span>
                                    </div>

                                    <CDropdownItem>
                                      <div class="d-flex align-items-center" @click="deletePackageContent(activeOnboardingPackage.package_name, activeOnboardingPackage.onboarding_package_id_external, props.row.onboarding_package_content_id_external)">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-trash"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('learn.Delete_package_content')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                    <CDropdownItem>
                                      <div class="d-flex align-items-center" @click="openSidebarRight('onboarding_package_content_details', { onboarding_package: props.row.onboarding_package, onboarding_package_content_id_external: props.row.onboarding_package_content_id_external })">
                                        <div class="post_action_icon text-center mr-1">
                                          <i class="fas fa-pen"/>
                                        </div>
                                        <div class="flex-grow-1">
                                          <span>{{$t('learn.Edit_package_content')}}</span>
                                        </div>
                                      </div>
                                    </CDropdownItem>
                                  </CDropdown>
                                </div>
                              </b-table-column>
                            </template>
                            <template slot="empty">
                              <div class="text-center">
                                <span>{{$t('common.no_subcategories_found')}}</span>
                              </div>                
                            </template> 
                      </b-table>
                    </div>          
                  </CCol>
                </CRow>
                <CRow v-else class="m-0">
                  <CCol cols="12" lg="12">
                    <span>{{$t('learn.Select_onboarding_package_for_content')}}</span>
                  </CCol>
                </CRow>          
              </div>
            </CCol>
          </CRow>          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import targetGroupPopover from '@/components/common/targetGroupPopover.vue';

export default {
  name: 'Onboarding',
  components: {
    Multiselect,
    loadingSpinner,
    noPermission,
    targetGroupPopover
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      contentFocus: false,
      activeOnboardingPackage: {
        package_name: null,
        onboarding_package_id_external: null
      },
      targetGroups: [],
      filter: { target_groups: [] },
      onboardingPackages: [],
      onboardingPackagesLoading: false,
      onboardingPackagesLoaded: false,
      onboardingPackagesPaginated: false,
      onboardingPackagesPerPage: 8,
      onboardingPackagesPage: 1,
      onboardingPackageStartIndex: 0,     
      onboardingPackageTotal: 0,
      onboardingPackageContent: [],
      onboardingPackageContentLoading: false,
      onboardingPackageContentPaginated: false,
      onboardingPackageContentPerPage: 8,
      onboardingPackageContentPage: 1,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      searchEnabled: true,
      showInactive: false,
    }
  },
  methods: {
    onOnboardingPackagePageChange(page) {
      // Set the onboardingPackageStartIndex value
      (page > 1) ? this.onboardingPackageStartIndex = (page - 1)*10 : this.onboardingPackageStartIndex = 0;
      // Get the rewards
      this.getOnboardingPackages();
    },
    getOnboardingPackages() {
      // Start the loader
      if(this.onboardingPackagesLoaded === false) this.onboardingPackagesLoading = true;
      // Set the params
      let params = {};      
      params.target_groups = [];
      // Add the group_id to the target_groups array
      for(var g = 0; g < this.filter.target_groups.length; g++) {
        params.target_groups.push(this.filter.target_groups[g].group_id);
      }      
      // Get the onboarding package
      axios.post(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/packages/' + this.onboardingPackageStartIndex, params)
      .then(res => {    
        // Clear the onboardingPackages array
        this.onboardingPackages = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_onboarding_packages;
        // Update the currentTotal value
        if(currentTotal / this.onboardingPackagesPerPage > 1000) currentTotal = this.onboardingPackagesPerPage * 1000;
        // Update the onboardingPackageTotal value
        this.onboardingPackageTotal = currentTotal;
        // Add the onboarding_packages to the groups array
        this.onboardingPackages = res.data.data.onboarding_packages;
        // Enable the pagination if necessary
        (this.onboardingPackageTotal > this.onboardingPackagesPerPage) ? this.onboardingPackagesPaginated = true : this.onboardingPackagesPaginated = false;
        // Check if the inactive users should be shown
        if(!this.showInactive) this.onboardingPackages = this.onboardingPackages.filter( i => ['Y'].includes( i.active ) );
        // Stop the loader
        this.onboardingPackagesLoading = false;
        // Update the onboardingPackagesLoaded value
        this.onboardingPackagesLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getOnboardingPackageDetails(onboardingPackageIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/' + onboardingPackageIdExternal)
      .then(res => {
        let updatedOnboardingPackage = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.onboardingPackageTable !== undefined ) {
          // Get the package index
          let packageIndex = this.$refs.onboardingPackageTable.data.findIndex(x => x.onboarding_package_id_external == updatedOnboardingPackage.onboarding_package_id_external);
          // Update the is active value for the row
          this.$refs.onboardingPackageTable.data[packageIndex].package_name = updatedOnboardingPackage.package_name;
          this.$refs.onboardingPackageTable.data[packageIndex].package_description = updatedOnboardingPackage.package_description;
          this.$refs.onboardingPackageTable.data[packageIndex].active = updatedOnboardingPackage.active;
          this.$refs.onboardingPackageTable.data[packageIndex].is_active = updatedOnboardingPackage.is_active;
          this.$refs.onboardingPackageTable.data[packageIndex].target_groups = updatedOnboardingPackage.target_groups;
          // Hide (newly) inactive onboardingPackages  
          if(!this.showInactive) this.onboardingPackages = this.onboardingPackages.filter( i => ['Y'].includes( i.active ) );
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getOnboardingPackageContent(onboardingPackageName, onboardingPackageIdExternal) {
      // Check if a different onboarding package is selected
      if(this.activeOnboardingPackage.onboarding_package_id_external !== onboardingPackageIdExternal) {
        // Start the loader
        this.onboardingPackageContentLoading = true;      
        // Clear the subcategories (if necessary) and items            
        this.onboardingPackageContent = [];
        // Update the active data
        this.activeOnboardingPackage = { package_name: onboardingPackageName, onboarding_package_id_external: onboardingPackageIdExternal };
      }
      // Get the subcategories
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/' + onboardingPackageIdExternal + '/content')
      .then(res => {
        this.onboardingPackageContent = res.data.data;
        // Enable the pagination if necessary
        (this.onboardingPackageContent.length > this.onboardingPackageContentPerPage) ? this.onboardingPackageContentPaginated = true : this.onboardingPackageContentPaginated = false;        
        // Stop the loader
        this.onboardingPackageContentLoading = false;
      })
      .catch(err => {
        console.error(err); 
      });      
    },
    getOnboardingPackageContentDetails(onboardingPackageContentIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/content/' + onboardingPackageContentIdExternal)
      .then(res => {
        let updatedOnboardingPackageContent = res.data.data;
        // Check if table refs are available
        if(this.$refs !== undefined && this.$refs.onboardingPackageContentTable !== undefined ) {
          // Get the package index
          let packageContentIndex = this.$refs.onboardingPackageContentTable.data.findIndex(x => x.onboarding_package_content_id_external == updatedOnboardingPackageContent.onboarding_package_content_id_external);
          // Update the is active value for the row
          this.$refs.onboardingPackageContentTable.data[packageContentIndex].content_name = updatedOnboardingPackageContent.content_name;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    deletePackageContent(onboardingPackageName, onboardingPackageIdExternal, onboardingPackageContentIdExternal) {   
      axios.delete(process.env.VUE_APP_API_URL + '/v1/learn/onboarding/package/content/' + onboardingPackageContentIdExternal)
      .then(res => {
        // Show success notice
        this.$buefy.toast.open({ message: this.$t('learn.Onboarding_package_content_deleted'), type: 'is-success', duration: 2000 });
        // Reload the onboarding package content
        this.getOnboardingPackageContent(onboardingPackageName, onboardingPackageIdExternal);                        
      })
      .catch(err => {
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    },
    getTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.targetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },          
    showOnboardingInsights() {
      this.$router.push({path: '/insights/learn/onboarding'});
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }    
  },
  mounted: function() {
    this.getPlatformPermissions();
    this.getOnboardingPackages();
    this.getTargetGroups();

    this.$bus.$on('update_onboarding_packages', () => {
      this.getOnboardingPackages();
    });

    this.$bus.$on('update_onboarding_package_details', (onboardingPackageIdExternal) => {
      this.getOnboardingPackageDetails(onboardingPackageIdExternal);
    });

    this.$bus.$on('update_onboarding_package_content', (onboardingPackage) => {
      this.getOnboardingPackageContent(onboardingPackage.package_name, onboardingPackage.onboarding_package_id_external);
    });

    this.$bus.$on('update_onboarding_package_content_details', (onboardingPackageContentIdExternal) => {
      this.getOnboardingPackageContentDetails(onboardingPackageContentIdExternal);
    });    
  },
  beforeDestroy() {
    this.$bus.$off('update_onboarding_packages');
    this.$bus.$off('update_onboarding_package_details');
    this.$bus.$off('update_onboarding_package_content');
    this.$bus.$off('update_onboarding_package_content_details');
  } 
}
</script>